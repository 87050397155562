.TextsContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-left: 8px;
  min-height: fit-content;
}

.Texts {
  display: flex;
  flex-wrap: wrap;
  margin-left: 8px;
  min-height: fit-content;
  font-size: 1em;
}

.Input {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 1em;
  min-height: 1em;
  height: fit-content;
  max-height: 100%;
  display: inline;
  display: -webkit-inline;
  display: -ms-inline;
}

.Input:empty:before {
  content: attr(placeholder);
}

.Completion {
  color: #d15931;
}

.Buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}

.InnerButtons {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 10px;
  margin-bottom: 8px;
}
